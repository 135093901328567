/*------------------------------------------------------------------
Project:    Nairobi Personal Portfolio
Version:    1.0
Primary use:    pxdraft

[Table of contents]

1. Body
2. Header
3. Footer
4. Blog
5. Portfolio
6. Section
7. Home Banner
8. About Us
9. Services
10. Skill
11. Testimonials
12. Contact Us

-------------------------------------------------------------------*/
@import "../plugin/bootstrap/css/bootstrap.min.css";
@import "../plugin/themify-icons/themify-icons.css";
@import "../plugin/owl-carousel/css/owl.carousel.min.css";
@import "../plugin/magnific/magnific-popup.css";


@import "scss/variable";
@import "scss/mixin";
@import "scss/base";
@import "scss/button";
@import "scss/color";
@import "scss/header";
@import "scss/footer";
@import "scss/blog";
@import "scss/portfolio";
@import "scss/style";
