@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');



$px-theme:			#ed9b18 !default;
$px-dark:			#000 !default;

$px-white:			#fff !default;
$px-black:			#000 !default;

$px-gray:			#fff5ec !default;

$px-body:			#5e6472 !default;
$px-font-size:		16px !default;
$px-line-height:	1.7 !default;
$px-font:			'Manrope', monospac !default;
$px-font-alt:		'Manrope', serif !default;

$px-shadow:			rgba(0, 0, 0, 0.25) 0px 25px 50px -12px !default;
$px-shadow-sm:			rgba(0, 0, 0, 0.25) 0px 7px 20px -22px !default;


// Breakpoints
$px-media-xsm:		567px !default;
$px-media-sm:		767px !default;
$px-media-md:		991px !default;
$px-media-lg:		1200px !default;